<template>
  <div class="Create">
    <div class="box">
      <div class="left" v-if="$route.query.type == 2">
        <div class="dataImg">
          <el-image
            style="height: 100%; width: 100%; object-fit: cover"
            :src="agoData.imageUrl"
            fit="cover"
          ></el-image>
          <div class="title">{{ agoData.title }}</div>
          <div class="bottom">
            <el-avatar
              shape="square"
              size="small"
              :src="agoData.authorHeadImage"
            />
            <span class="nickName">{{ agoData.aauthorName }}</span>
          </div>
        </div>
        <div class="conte">
          <div class="item">
            <span class="label">上文层数：</span>
            <span class="value">{{ agoData.level }}层</span>
          </div>
        </div>
        <div class="title">几人作续写者NFT</div>
        <img
          style="width: 160px; height: 250px; margin-right: 38px"
          src="@/assets/images/Continuation.png"
          alt=""
        />
        <div class="conte">

          <div class="item">
         您正在使用AI进行开篇作品的创作……
          </div>
<!-- 
          <div class="item">

            <span class="label">合约地址：</span>
            <span class="value">{{ nft.contractAddress }}</span>
          </div>
          <div class="item">
            <span class="label">ID：</span>
            <span class="value">{{ nft.articleId }}</span>
          </div>
          <div class="item">
            <span class="label">兑换时间：</span>
            <span class="value">{{ nft.redeemTime }}</span>
          </div>
          <div class="item">
            <span class="label">权限状态：</span>
            <span class="value">暂未绑定作品</span>
          </div> -->
        </div>
      </div>
      <div class="left" v-else>
        <div class="title">几人作开篇人NFT</div>
        <div class="imgs">
          <img src="@/assets/images/creatImg.png" alt="" />
        </div>
        <div class="conte">

          <div class="item">
         您正在使用AI进行开篇作品的创作……
          </div>
        </div>
      </div>

      <div class="right">
        <router-view :layer="layer" :authorId="authorId"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { detail } from "@/api/article";
export default {
  name: "Create",
  data() {
    return {
      agoData: {
        article: { title: "", pics: [{ url: "" }] },
        author: { avatar: "", name: "" },
      },
      layer: 0,
      authorId: 0,
    };
  },
  mounted() {
    this.getAgo();
  },
  methods: {
    getAgo() {
      // 获取原创作信息
      if (this.$route.query.type == 2) {
         detail({ accountId: this.accountId, articleId: this.agoId }).then(({ code, data }) => {
          if (code==1000) {
            this.agoData = data;
            this.layer = data.layer;
            this.authorId = data.authorId.id;
          }
        });
      } else {
        this.layer = 0;
        this.authorId = this.accountId;
      }
    },
  },
  computed: {
    ...mapState({
      accountId: (state) => state.user.accountId,
      agoId: (state) => state.page.agoId,
      nft: (state) => state.page.nft,
    }),
  },
};
</script>

<style lang="less" scoped>
.Create {
  width: 100%;
  box-sizing: border-box;
  background: url(../../assets/images/creatbg.png) no-repeat;
  background-size: 100% 100%;

  .box {
    display: flex;
    .left {
      width: 26vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: column;

      .dataImg {
        position: relative;
        height: 130px;
        width: 160px;
        border: solid 4px #999;
        border-radius: 8px;
        margin-right: 34px;
        .title {
          box-sizing: border-box;
          width: 100%;
          position: absolute;
          bottom: 32px;
          font-size: 14px;
          line-height: 26px;
          color: #fff;
          padding-left: 6px;
          background-color: rgba(0, 0, 0, 0.7);
        }
        .bottom {
          box-sizing: border-box;
          position: absolute;
          width: 100%;
          display: flex;
          padding-left: 8px;
          align-items: center;
          color: #ccc;
          bottom: 0;
          padding-bottom: 4px;
          background-color: rgba(0, 0, 0, 0.7);
          .nickName {
            margin-left: 16px;
          }
        }
      }
      .imgs {
        width: 160px;
        height: 250px;
        // border-radius: 8px;
        // overflow: hidden;
        // border: 4px solid #999;
        img {
          width: 100%;
        }
      }
      .title {
        line-height: 50px;
        font-size: 16px;
        width: 160px;
      }
      .conte {
        width: 180px;
        padding-top: 20px;
        .item {
          width: 100%;
          line-height: 24px;
          font-size: 12px;
          .label {
            display: inline-block;
            width: 70px;
          }
          .value {
            color: #828282;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .right {
      width: 56vw;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      flex-flow: column;
    }
  }
}
</style>
